import React from 'react'
import styled from 'styled-components'
import { images } from '../assets/assets'

const HeroWrapper = styled.section`
  background-color: ${({ theme }) => theme.highlight};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 200px;
`

const LeftImage = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  ${({ theme }) => theme.sm`
    display: block;
  `}
  img {
    width: 300px;
  }
`

const RightImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  img {
    width: 300px;
  }
`

const StyledH2 = styled.h2`
  position: relative;
  z-index: 99;
  font-size: ${({ theme }) => theme.fontSizes[5]};
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.text};
  ${({ theme }) => theme.sm`
      font-size: ${({ theme }) => theme.fontSizes[6]};
  `}
`

const Hero = ({ title }) => {
  return (
    <HeroWrapper>
      <LeftImage>
        <img src={images.eminovaNetleft} alt="Background" />
      </LeftImage>
      <RightImage>
        <img src={images.eminovaNetright} alt="Background" />
      </RightImage>
      <StyledH2>{title}</StyledH2>
    </HeroWrapper>
  )
}

export default Hero
