import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'

/////////////////////////////////////////////////////////////////////
// APP CONTEXT
////////////////////////////////////////////////////////////////////

export const AppContext = createContext(null)

const AppProvider = (props) => {
  const [contextItems, setContextItems] = useState(null)
  const [contextIntro, setContextIntro] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    const password = process.env.REACT_APP_BASIC_AUTH_TOKEN
    const login = process.env.REACT_APP_BASIC_AUTH_LOGIN
    setLoading(true)
    const getPosts = axios.get(
      'https://eminova.se/wp-json/wp/v2/partner-news',
      {
        auth: {
          username: login,
          password: password,
        },
      }
    )

    const getIntro = axios.get(
      'https://eminova.se/wp-json/partner-news/v1/partner-news',
      {
        auth: {
          username: login,
          password: password,
        },
      }
    )
    axios
      .all([getPosts, getIntro])
      .then(
        axios.spread(function (res1, res2) {
          setContextItems(res1.data)
          setContextIntro(res2.data)
          setLoading(false)
        })
      )
      .catch((err) => {
        setLoading(false)
        setError(true)
      })
  }, [])

  return (
    <AppContext.Provider
      value={{
        contextItems,
        contextIntro,
        error,
        loading,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider
