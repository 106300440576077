import React from 'react'

const Arrow = () => {
  return (
    <svg
      width="13px"
      height="auto"
      viewBox="0 0 256 256"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M91.474,33.861l-89.6,89.6c-2.5,2.5-2.5,6.551,0,9.051l89.6,89.6c2.5,2.5,6.551,2.5,9.051,0s2.5-6.742,0-9.242L21.849,134  H249.6c3.535,0,6.4-2.466,6.4-6s-2.865-6-6.4-6H21.849l78.676-78.881c1.25-1.25,1.875-2.993,1.875-4.629s-0.625-3.326-1.875-4.576  C98.024,31.413,93.974,31.361,91.474,33.861z" />
    </svg>
  )
}

export default Arrow
