import React from 'react'
import styled from 'styled-components'
import LoginButton from './login-button'
import LogoutButton from './logout-button'
import { images } from '../assets/assets'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'

const StyledMainNav = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledImage = styled(Link)`
  height: auto;
  width: 150.5px;
  ${({ theme }) => theme.sm`
      margin-left: 14px;
  `}
  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
`

const AuthNav = () => {
  const { isAuthenticated } = useAuth0()

  return isAuthenticated ? <LogoutButton /> : <LoginButton />
}

const MainNav = () => (
  <StyledMainNav>
    <StyledImage to="/">
      <img src={images.logoNavigation} alt="Eminova Logo" />
    </StyledImage>
    <AuthNav />
  </StyledMainNav>
)

export default MainNav
