import React from 'react'
import { Switch } from 'react-router-dom'
import ProtectedRoute from './auth/protected-route'
import AppProvider from './context/AppContext'
import { useAuth0 } from '@auth0/auth0-react'
import Footer from './components/footer'
import Loading from './components/loading'
import NavBar from './components/nav-bar'
import Posts from './views/posts'
import Post from './views/post'
import NotFound from './views/404'
import styled from 'styled-components'

const StyledContent = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const App = () => {
  const { isLoading } = useAuth0()

  if (isLoading) {
    return <Loading />
  }
  return (
    <AppProvider>
      <StyledContent>
        <NavBar />
        <Switch>
          <ProtectedRoute exact path="/" component={Posts} />
          <ProtectedRoute exact path="/post/:id" component={Post} />
          <ProtectedRoute component={NotFound} />
        </Switch>
        <Footer />
      </StyledContent>
    </AppProvider>
  )
}

export default App
