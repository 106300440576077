import { css } from 'styled-components'

export const breakpoints = {
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1024px',
  xl: '1440px',
  xlarge: 'Infinity',
}

export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) =>
    css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `
  return accumulator
}, {})

const theme = {
  primary: '#fff',
  secondary: '#000',
  highlight: '#D1E2C9',
  highlightText: '#7DBE61',
  card: '#F3F3F3',
  footer: '#f7f7f7',
  text: '#222222',
  fontSizes: ['12px', '14px', '16px', '18px', '20px', '30px', '40px'],
  fontWeights: [300, 400, 500, 600, 700],
  space: [
    '4px',
    '8px',
    '12px',
    '16px',
    '24px',
    '32px',
    '40px',
    '48px',
    '64px',
    '74px',
    '96px',
    '110px',
  ],
  ...media,
}
export default theme
