import React from 'react'
import { Link } from 'react-router-dom'
import { images } from '../assets/assets'
import styled from 'styled-components'

const PageWrapper = styled.main`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`
const StyledHero = styled.section`
  width: 100%;
  height: 200px;
  background-image: url(${images.eminovaBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`

const ContentWrapperCenter = styled.section`
  max-width: 1200px;
  width: 100%;
  height: 300px;
  padding: 0 ${({ theme }) => theme.space[2]};
  margin: ${({ theme }) => theme.space[8]} 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const NotFound = () => (
  <PageWrapper>
    <StyledHero />
    <ContentWrapperCenter>
      <p>
        Sidan du söker verkar inte finnas. Kontrollera att du skirivt rätt och
        försök igen.
      </p>
      <Link to="/">Tillbaka</Link>
    </ContentWrapperCenter>
  </PageWrapper>
)

export default NotFound
