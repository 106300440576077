import React from 'react'
import styled from 'styled-components'

const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

const Dot = styled.div`
  background-color: ${({ theme }) => theme.highlight};
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  animation: BounceAnimation 0.5s linear infinite;
  animation-delay: ${(props) => props.delay};

  @keyframes BounceAnimation {
    0% {
      margin-bottom: 0;
    }
    50% {
      margin-bottom: 8px;
    }
    100% {
      margin-bottom: 0;
    }
  }
`

const LoadingDots = () => {
  return (
    <DotWrapper>
      <Dot delay="0s" />
      <Dot delay=".1s" />
      <Dot delay=".2s" />
    </DotWrapper>
  )
}

export default LoadingDots
