import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Hero from '../components/hero'
import LoadingDots from '../components/loadingDots'
import parse from 'html-react-parser'
import { useAppContext } from '../context/AppContext'

const PageWrapper = styled.main`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

const PageContent = styled.section`
  max-width: 1200px;
  width: 100%;
  padding: 0 ${({ theme }) => theme.space[2]};
  margin: ${({ theme }) => theme.space[8]} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.sm`
    padding: 0 ${({ theme }) => theme.space[4]};
  `}
`

const ContentWrapper = styled.section`
  max-width: 1200px;
  width: 100%;
  margin: ${({ theme }) => theme.space[8]} 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  grid-gap: ${({ theme }) => theme.space[3]};
  ${({ theme }) => theme.sm`
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(230px, auto);
  `}
`

const ContentWrapperCenter = styled.section`
  max-width: 1200px;
  width: 100%;
  height: 300px;
  padding: 0 ${({ theme }) => theme.space[2]};
  margin: ${({ theme }) => theme.space[8]} 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledIntro = styled.article`
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.sm`
    flex-direction: row;
  `}
`

const StyledContent = styled.article`
  padding: ${({ theme }) => theme.space[4]};
  background-color: ${({ theme }) => theme.card};
  min-height: 222px;
  width: 100%;
`

const StyledImage = styled.img`
  width: 100%;
  height: 230px;
  object-fit: cover;
  ${({ theme }) => theme.sm`
    max-width: 316px;
    height: 230px;
  `}
`

const StyledText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  color: ${({ theme }) => theme.secondary};
  max-width: 730px;
  line-height: 170%;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  p {
    margin: 5px 0;
  }
  ul {
    margin-left: 30px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: ${({ theme }) => theme.fontSizes[3]};
    margin-top: ${({ theme }) => theme.space[3]};
    ${({ theme }) => theme.sm`
      font-size: ${({ theme }) => theme.fontSizes[4]};
    `}
  }
`

const StyledH2 = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  color: ${({ theme }) => theme.text};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  margin-bottom: ${({ theme }) => theme.space[2]};
`

const StyledDate = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.highlightText};
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.space[2]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
`

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[5]};
  margin-bottom: ${({ theme }) => theme.space[2]};
  ${({ theme }) => theme.sm`
    font-size: ${({ theme }) => theme.fontSizes[6]};
  `}
`

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Maj',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dec',
]

const NewsArticle = ({ item }) => {
  const date = new Date(item.created_at)
  return (
    <StyledLink to={`/post/${item.id}`} key={item.id}>
      <StyledImage src={item.thumbnail_url} alt="News" />
      <StyledContent>
        <StyledH2>{item.title}</StyledH2>
        {date && (
          <StyledDate>
            {date.getDate()} {MONTHS[date.getMonth()]} {date.getFullYear()}
          </StyledDate>
        )}
        <StyledText>{parse(item.excerpt)}</StyledText>
      </StyledContent>
    </StyledLink>
  )
}

const Posts = () => {
  const { loading, error, contextItems, contextIntro } = useAppContext()

  if (error) {
    return (
      <PageWrapper>
        <Hero title="Eminova Adviser Portal" />
        <ContentWrapper>
          <p>Oops! Någonting gick fel.</p>
        </ContentWrapper>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <Hero title="Eminova Adviser Portal" />
      {loading ? (
        <ContentWrapperCenter>
          <LoadingDots />
        </ContentWrapperCenter>
      ) : (
        <PageContent>
          {contextIntro && contextIntro.title && (
            <StyledH1>{contextIntro.title}</StyledH1>
          )}
          {contextIntro && contextIntro.body && (
            <StyledIntro>{contextIntro.body}</StyledIntro>
          )}
          <ContentWrapper>
            {contextItems &&
              contextItems.map((item) => {
                return <NewsArticle item={item} />
              })}
          </ContentWrapper>
        </PageContent>
      )}
    </PageWrapper>
  )
}

export default Posts
