export const images = {
  backgroundFooter: require('./images/background-eminova.png'),
  DBSeal: require('./images/DBSeal.png'),
  logoFooter: require('./images/eminovafooter.png'),
  logoNavigation: require('./images/eminovaNavigation.jpeg'),
  eminovaNetleft: require('./images/eminovaNetleft.png'),
  eminovaNetright: require('./images/eminovaNetright.png'),
  eminovaBackground: require('./images/unsplash_sp-p7uuT0tw.png'),
  cardImage: require('./images/unsplash_ESkw2ayO2As.png'),
  linkedIn: require('./images/linkedin.png'),
}
