import React from 'react'
import styled from 'styled-components'
import { images } from '../assets/assets'

const StyledFooter = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.footer};
  width: 100%;
  overflow: hidden;
  position: relative;
`

const StyledBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`

const StyledContainer = styled.div`
  padding: 0 ${({ theme }) => theme.space[3]};
  padding-top: ${({ theme }) => theme.space[7]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 100;
  ${({ theme }) => theme.sm`
      flex-direction: row;
      padding: 0 ${({ theme }) => theme.space[7]};
      padding-top: ${({ theme }) => theme.space[7]};
  `}
`

const StyledInnerContainer = styled.div`
  flex: 2;
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  ${({ theme }) => theme.sm`
      flex-direction: row;
  `}
  ul {
    margin-right: ${({ theme }) => theme.space[7]};
    margin-bottom: ${({ theme }) => theme.space[7]};
    li {
      list-style-type: none;
      letter-spacing: 0.08em;
      line-height: 200%;
      font-size: ${({ theme }) => theme.fontSizes[1]};
      a {
        text-decoration: none;
        color: ${({ theme }) => theme.text};
      }
      span {
        color: ${({ theme }) => theme.text};
        font-weight: ${({ theme }) => theme.fontWeights[1]};
        font-size: ${({ theme }) => theme.fontSizes[3]};
        text-transform: uppercase;
      }
      &:first-of-type {
        a {
          font-weight: ${({ theme }) => theme.fontWeights[1]};
          font-size: ${({ theme }) => theme.fontSizes[3]};
          text-transform: uppercase;
        }
      }
    }
  }
`
const StyledIcon = styled.a`
  img {
    width: 20px;
    height: 20px;
    margin-top: ${({ theme }) => theme.space[1]};
  }
`

const StyledImage = styled.a`
  flex: 1;
  ${({ theme }) => theme.sm`
    text-align: center;
  `}
  img {
    max-width: 150px;
    margin-bottom: ${({ theme }) => theme.space[5]};
  }
`

const StyledLogo = styled.a`
  flex: 2;
  align-self: flex-end;
  text-align: right;
  img {
    max-width: 380px;
    max-height: 200px;
  }
`

const Footer = () => (
  <StyledFooter>
    <StyledBackground>
      <img src={images.backgroundFooter} alt="Background" />
    </StyledBackground>
    <StyledContainer>
      <StyledInnerContainer>
        <ul>
          <li>
            <span>EMINOVA</span>
          </li>
          <li>
            <a
              href="https://eminova.se"
              rel="noopener noreferrer"
              target="_blank"
            >
              www.eminova.se
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/eminovafondkommissionab"
            >
              Följ oss på Linkedin
            </a>
          </li>
          <StyledIcon
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.linkedin.com/company/eminovafondkommissionab"
          >
            <img src={images.linkedIn} alt="LinkedIn Icon" />
          </StyledIcon>
        </ul>
        <ul>
          <li>
            <span>KONTAKTA OSS</span>
          </li>
          <li>
            <a href="tel:08-684 211 10">08-684 211 10</a>
          </li>
          <li>
            <a href="mailto:adviser@eminova.se">adviser@eminova.se</a>
          </li>
          <li>Biblioteksgatan 3, 3 tr</li>
          <li>111 46 STOCKHOLM</li>
        </ul>
      </StyledInnerContainer>
      <StyledImage
        href="https://upplysningar.syna.se/Sigill/Detail/1202316"
        alt="Sigillet är utfärdat av kreditupplysningsföretaget AB Syna www.syna.se"
        title="Sigillet är utfärdat av kreditupplysningsföretaget AB Syna"
        target="_blank"
      >
        <img src={images.DBSeal} alt="DBSeal" />
      </StyledImage>
      <StyledLogo href="https://eminova.se/">
        <img src={images.logoFooter} alt="Eminova logo" />
      </StyledLogo>
    </StyledContainer>
  </StyledFooter>
)

export default Footer
