import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import styled from 'styled-components'

const BtnLogout = styled.button`
  height: 36px;
  width: 80px;
  border: 1px solid ${({ theme }) => theme.secondary};
  background-color: ${({ theme }) => theme.primary};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  ${({ theme }) => theme.sm`
      margin-right: 14px;
  `}

  span {
    color: ${({ theme }) => theme.text};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    font-weight: ${({ theme }) => theme.fontWeights[1]};
    margin-bottom: 3px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.highlight};
    color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
  }
`

const LogoutButton = () => {
  const { logout } = useAuth0()
  return (
    <BtnLogout
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      <span>Logga ut</span>
    </BtnLogout>
  )
}

export default LogoutButton
