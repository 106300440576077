import React from 'react'
import MainNav from './main-nav'
import styled from 'styled-components'

const StyledNavBar = styled.header`
  height: 60px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[3]};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.primary};
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
`

const NavBar = () => {
  return (
    <StyledNavBar>
      <MainNav />
    </StyledNavBar>
  )
}

export default NavBar
