import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LoadingDots from '../components/loadingDots'
import { images } from '../assets/assets'
import Arrow from '../icons/arrow'
import { useAppContext } from '../context/AppContext'
import parse from 'html-react-parser'

const PageWrapper = styled.main`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

const PageContent = styled.section`
  max-width: 1200px;
  width: 100%;
  padding: 0 ${({ theme }) => theme.space[2]};
  margin-top: ${({ theme }) => theme.space[8]};
  margin-bottom: ${({ theme }) => theme.space[8]};
  ${({ theme }) => theme.sm`
    padding: 0 ${({ theme }) => theme.space[4]};
  `}
`

const ContentWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;
  width: 100%;
  ${({ theme }) => theme.sm`
    grid-template-columns: repeat(2, minmax(250px, 1fr));
    grid-gap: ${({ theme }) => theme.space[7]};
  `}
`

const StyledH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[5]};
  margin-bottom: ${({ theme }) => theme.space[2]};
  ${({ theme }) => theme.sm`
    font-size: ${({ theme }) => theme.fontSizes[6]};
  `}
`

const StyledText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  color: ${({ theme }) => theme.secondary};
  line-height: 170%;
  margin: ${({ theme }) => theme.space[3]} 0;
  p {
    margin: 8px 0;
  }
  ul {
    margin-left: 30px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: ${({ theme }) => theme.fontSizes[3]};
    margin-top: ${({ theme }) => theme.space[3]};
    ${({ theme }) => theme.sm`
      font-size: ${({ theme }) => theme.fontSizes[4]};
    `}
  }
`

const StyledDate = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.highlightText};
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.space[2]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
`

const StyledHero = styled.section`
  width: 100%;
  height: 200px;
  background-image: url(${images.eminovaBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledDownload = styled.a`
  height: 51px;
  display: flex;
  border: 1px solid ${({ theme }) => theme.secondary};
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.highlightText};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  text-align: left;
  padding: 0 ${({ theme }) => theme.space[2]};
  margin-bottom: ${({ theme }) => theme.space[2]};
  cursor: pointer;
  text-decoration: none;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.highlight};
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.primary};
  }
`

const StyledLink = styled(Link)`
  width: 25px;
  height: 25px;
  display: flex;
  align-self: flex-start;
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.space[4]};
  }

  @keyframes bounce {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(0px);
    }
    75% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  &:hover {
    animation: bounce 2s infinite;
  }
`

const ContentWrapperCenter = styled.section`
  max-width: 1200px;
  width: 100%;
  height: 300px;
  padding: 0 ${({ theme }) => theme.space[2]};
  margin: ${({ theme }) => theme.space[8]} 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BtnDownload = ({ link, text, target }) => {
  return (
    <StyledDownload target={target ? target : '_blank'} href={link}>
      {text}
    </StyledDownload>
  )
}

const Post = () => {
  const { id } = useParams()
  const { contextItems, loading } = useAppContext()
  const [filterdItem, setfilterdItem] = useState(null)

  const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
  ]

  useEffect(() => {
    if (contextItems) {
      setfilterdItem(contextItems.filter((item) => item.id === Number(id)))
    }
  }, [contextItems, id])

  return (
    <PageWrapper>
      <StyledHero />
      {loading ? (
        <ContentWrapperCenter>
          <LoadingDots />
        </ContentWrapperCenter>
      ) : filterdItem && filterdItem.length > 0 ? (
        <>
          {filterdItem.map((item) => {
            const date = new Date(filterdItem && filterdItem[0].created_at)
            return (
              <PageContent key={item.id}>
                <StyledLink to="/">
                  <Arrow />
                </StyledLink>
                <ContentWrapper key={item.id}>
                  <article>
                    <StyledH1>{item.title}</StyledH1>
                    {date && (
                      <StyledDate>
                        {date.getDate()} {MONTHS[date.getMonth()]}{' '}
                        {date.getFullYear()}
                      </StyledDate>
                    )}

                    <StyledText>{parse(item.post_content)}</StyledText>
                  </article>
                  <div>
                    {item.pdfs &&
                      item.pdfs.map((pdf, index) => (
                        <BtnContainer key={index}>
                          <BtnDownload link={pdf.url} text={pdf.title} />
                        </BtnContainer>
                      ))}
                    {item.links &&
                      item.links.map((link, index) => (
                        <BtnContainer key={index}>
                          <BtnDownload
                            target={link.link.target}
                            link={link.link.url}
                            text={link.link.title}
                          />
                        </BtnContainer>
                      ))}
                  </div>
                </ContentWrapper>
              </PageContent>
            )
          })}
        </>
      ) : (
        <ContentWrapperCenter>
          <p>
            Sidan du söker verkar inte finnas. Kontrollera att du skirivt rätt
            och försök igen.
          </p>
          <Link to="/">Tillbaka</Link>
        </ContentWrapperCenter>
      )}
    </PageWrapper>
  )
}

export default Post
