import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ThemeProvider } from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import theme from './styles/theme'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './components/scrollToTop'
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
require('typeface-lato')

const GlobalStyle = createGlobalStyle`
  html, a, button, body, div, span, applet, object, input, select, option, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: Lato, Sans-Serif;
    font-weight: 300;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    font-size: 18px;
    background-color: #fff;
  }
  textarea, input {
    font-family: Lato, Sans-Serif;
    font-size: 18px;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Auth0ProviderWithHistory>
        <GlobalStyle />
        <ScrollToTop />
        <App />
      </Auth0ProviderWithHistory>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
